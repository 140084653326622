@import 'styles/utils/index';

.table-wrapper {
  position: relative;
  padding: rem(0 15px 124px);

  @include mq($until: tablet) {
    margin-top: 100px;
    display: none;
  }

  table {
    border-collapse: collapse;
    max-width: $layout-base-1;
    margin: 0 auto;
    width: 100%;

    thead {
      &:after {
        content: '';
        position: absolute;
        height: rem(1px);
        background: $grey-100;
        top: rem(66px);
        left: 0;
        right: 0;
      }

      th:first-child {
        text-align: left;
      }

      .table-columns {
        p {
          margin: rem(16px auto);
          font-weight: 700;
          font-size: rem(24px);
          line-height: rem(32px);
        }

        &-secondary {
          color: $font-4;
        }

        &-primary {
          color: $main-700;
        }

        &-ghost {
          color: $font-3;
        }
      }
    }

    tbody {
      tr {
        border-bottom: rem(1px) solid $grey-100;
        height: rem(56px);

        td {
          &.table-group-name {
            font-size: rem(18px);
            color: $font-4;
            font-weight: 700;
            line-height: rem($text-body-height-2);
            padding: rem(32px 0 16px);
          }

          p {
            font-weight: 400;
            font-size: rem(16px);
            line-height: rem(24px);
            color: $font-4;
            text-align: center;
            margin: 0;

            .category-icon-cross,
            .category-icon-check {
              font-size: rem(20px);
              position: relative;
              top: rem(2.5px);
            }

            .category-icon-cross path {
              fill: $font-2;
            }

            .category-icon-check path {
              fill: $main-500;
            }

            span {
              margin-left: rem(4px);
            }
          }
        }
      }
    }

    @include mq($until: wide) {
      max-width: 1140px;
      width: 100%;
    }
  }

  .table-columns-footnote {
    display: block;
    margin: rem(32px auto 0);
    max-width: $layout-base-1;
    font-weight: 400;
    font-size: rem(16px);
    line-height: rem(24px);
    color: $font-3;

    @include mq($until: wide) {
      max-width: 1140px;
      width: 100%;
    }
  }

  .table-collapse {
    overflow: hidden;
    height: rem(700px);
    transition: height 0.2ms;

    &.collapsed {
      height: 100%;
    }
  }

  .table-collapse-toggler {
    width: 100%;
    background: linear-gradient(0deg, rgb(255, 255, 255) 60%, rgba(255, 255, 255, 0.2) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: rem(40px 0);

    &:not(.collapsed) {
      position: relative;
      padding-top: rem(100px);
      top: rem(-70px);
    }

    button {
      display: block;
    }
  }
}
