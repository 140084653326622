@import 'styles/utils/index';

.configure-columns {
  margin-top: rem(12px);
  font-size: rem(16px);
  background: $grey-000;
  padding: rem(44px 26px 28px);
  margin-left: rem(-60px);
  margin-right: rem(-60px);
  width: calc(100% + #{rem(120px)});

  @include mq($until: tablet) {
    margin-left: rem(-20px);
    margin-right: rem(-20px);
    width: calc(100% + #{rem(40px)});
  }

  .configure-columns_card {
    flex-shrink: 0;
    border-radius: rem(2px);
    background: $white;
    box-shadow: rem(0) rem(7px) rem(27px) rem(3px) rgba(31, 105, 179, 0.03);
    padding: rem(14px);
    max-width: rem(225px);

    &:not(:last-child) {
      margin-right: rem(14px);
    }

    button {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }

    .configure-columns_select {
      height: rem(45px);

      :global(.rc-select-selection-item),
      :global(.rc-select-selection-placeholder),
      input {
        font-size: rem(16px);
        padding: rem(7px);
      }

      :global(.rc-select-arrow) {
        top: (15px);
        right: rem(12px);
      }

      :global(.rc-select-item) {
        font-size: rem(16px);
        padding: rem(7px);
      }

      &.shake {
        @include shake-animation(0.2s);
      }
    }
  }

  .configure-columns_active {
    border: 1px solid $main-700;
  }

  .configure-columns_context {
    display: flex;
    flex-direction: column;
    margin: rem(8px 0);
    padding: rem(0 2px);
    overflow-y: auto;
    color: $font-3;

    span {
      margin: rem(7px);
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: rem(16px);
      line-height: 1.1em;
    }

    .configure-columns_title {
      font-weight: 600;
    }
  }

  &_slider {
    display: flex;
    position: relative;
  }
}

.configure-columns_headers {
  margin: rem(10px 0 16px);

  span {
    color: $font-3 !important;
    font-size: rem(16px) !important;
  }

  &:hover {
    div {
      border-color: $main-600 !important;
    }

    span {
      color: $font-3 !important;
    }
  }
}
